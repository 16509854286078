import React, {Component} from 'react';
import {Price} from '../utiles/utilidades'
import {motion} from 'framer-motion';

class ShowPago extends Component {
	constructor(props) {
		super(props)
		this.state = {
			gasto: this.props.gasto
		}
	}


	render() {
		return (
			<motion.div 
	          initial={{opacity: 0, scale: 0.8, y: 60}}
	          animate={{opacity: 1, scale: 1, y: 0}}
	          exit={{opacity: 0, scale: 0.8, y: 60}}>

				<div className="card">
					<div className="title">
			    		<div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
			    			<a href="#" onClick={() => this.props.cerrar()} className="ir-atras icon-arrow-left"></a>
							<h2>Detalles de movimiento</h2>
						</div>
					</div>
					<div className="colums">
			    		<div className="colum">
							<div className="ddl">
								<p>Identificador:</p>
								<span></span>
								<p>{this.state.gasto.id}</p>
							</div>
			    			<div className="ddl">
								<p>Titulo</p>
								<span></span>
								<p>{this.state.gasto.title}</p>
							</div>
							<div className="ddl">
								<p>Fecha:</p>
								<span></span>
								<p>{("date" in this.state.gasto) ? this.state.gasto.date.split(',')[0] : 'N/A'}</p>
							</div>
							<div className="ddl">
								<p>Hora:</p>
								<span></span>
								<p>{("date" in this.state.gasto) ? this.state.gasto.date.split(',')[1].replace('�', '') : 'N/A'}</p>
							</div>
			    		</div>
			    		<div className="colum">
							<div className="ddl">
								<p>Comercio</p>
								<span></span>
								<p>{this.state.gasto.name_store}</p>
							</div>
							<div className="ddl">
								<p>Tipo de gasto:</p>
								<span></span>
								<p>{this.state.gasto.type_spent}</p>
							</div>
							<div className="ddl">
								<p>Tipo de pago</p>
								<span></span>
								<p>{this.state.gasto.type}</p>
							</div>
							<div className="ddl">
								<p>Total cuenta</p>
								<span></span>
								<p>{Price(this.state.gasto.total)}</p>
							</div>
			    		</div>
			    	</div>
			    	<div className="divider"></div>
			    	<div className="group-form">
						<label>Proveedor</label>
						<input  name="description" readOnly={true} placeholder="..." value={this.state.gasto.provider} />
					</div>
			    	<div className="group-form">
						<label>Descripción</label>
						<textarea 
							name="description" 
							cols="30" 
							rows="4"
							readOnly={true}
							placeholder="..."
							value={this.state.gasto.description} 
						></textarea>
					</div>
				</div>
			</motion.div>
		)
	}
}
export default ShowPago;