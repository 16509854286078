import React from 'react';
import {Price, Color} from '../utiles/utilidades'
import Modal from '../components/modal';
import ShowPago from './ShowVenta'
import {motion} from 'framer-motion';
import ShowGasto from './ShowGasto'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  defaults
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';

defaults.color = '#eaeaea'
defaults.borderColor = '#878787'
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);


class ReportDetails extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			modal: false,
			active: 'ventas',
			active_chart: 'ventas',
			fecha: '',
			link: '',
			user: {},
			ventas: 0,
			gastos: 0,
			efectivo: 0,
			transferencia: 0,
			datafono: 0,
			cartera: 0,
			propinas: 0,
			movimientos: [],
			movimientosShow: [],
			gastos: [],
			gastosShow: [],
			ranking: [],
			total_vendidos: 0,
			stores: [],
			show_venta: null,
			show_gasto: null,
		}
		this.cierre = null
	}

	componentDidMount() {
		this.getEstadisticas();
		this.getStores()
	}

	getStores() {
		let pagados = this.props.informe.pagados;
		let stores = [];
		for(let pagado of pagados) {
			for(let data of pagado.data) {
				if(stores.filter((s) => s.id == data.store.id).length == 0) {
					stores.push(data.store)
				}
			}
		}
		this.setState({stores: stores});
	}

	getEstadisticas(id_store = null) {
			if(id_store == null || id_store == 'Todos los comercios') {
	      let pagados = this.props.informe.pagados.reverse();
	      let ventas = 0;
				let gastos = 0;
				let efectivo = 0;
				let transferencia = 0;
				let datafono = 0;
				let propinas = 0;
				for(let pagado of pagados) {
					if(pagado.type == 'Efectivo') {
						efectivo += pagado.total
					}
					if(pagado.type == 'Transferencia') {
						transferencia += pagado.total
					}
					if(pagado.type == 'Datafono') {
						datafono += pagado.total
					}
					ventas += pagado.total
					propinas += Number("propina" in pagado ? pagado.propina : 0)
				}

				let spents = this.props.informe.gastos.reverse();
				for(let spent of spents) {
					gastos += Number(spent.total)
				}
				this.cierre = this.props.informe
				this.setState({
					ventas: ventas,
					gastos: gastos,
					efectivo: efectivo,
					transferencia: transferencia,
					datafono: datafono,
					propinas: propinas,
					movimientos: pagados,
					movimientosShow: pagados,
					spents: spents, 
					spentsShow: spents,
					loading: false,
					fecha: this.props.informe.fecha,
					user: this.props.informe.user,
					link: 'https://informe.menuinsitu.com/index.php?uuid='+this.props.informe.uuid
				}, () => this.ranking())
			}else {
				let ventas = 0;
				let efectivo = 0;
				let transferencia = 0;
				let datafono = 0;
				this.props.informe.pagados.map(pa => {
					pa.data.map(d => {
						if(d.store.id == id_store) {

							d.products.map(pr => {
								if(pa.type == 'Efectivo') {
									efectivo += pr.total
								}
								if(pa.type == 'Transferencia') {
									transferencia += pr.total
								}
								if(pa.type == 'Datafono') {
									datafono += pr.total
								}
								ventas += pr.total
							})

						}
					})
				})

				let gastos = 0;
				let spents = this.props.informe.gastos.filter(g => g.id_store == id_store).reverse();
				for(let spent of spents) {
					gastos += Number(spent.total)
				}

				this.setState({
					ventas: ventas,
					gastos: gastos,
					efectivo: efectivo,
					transferencia: transferencia,
					datafono: datafono,
					spents: spents, 
					spentsShow: spents,
					loading: false,
				}, () => this.ranking(id_store))
			}
	}

	ranking(id_store = null) {
		let pagados = this.state.movimientos
		let productos = []
		let total_vendidos = 0
		for(let pago of pagados) {
			for(let d of pago.data) {
				if(id_store == null || id_store == 'Todos los comercios') {
					for(let producto of d.products) {
						if(productos.find(p => p.id == producto.id) != undefined) {
							productos = productos.map(item => {
								if(item.id == producto.id) {
									total_vendidos += Number(producto.amount)
									item.amounts += Number(producto.amount)
									item.total += Number(producto.total)
								}
								return item
							})
						}else {
							total_vendidos += Number(producto.amount)
							productos.push({
								id: producto.id,
								name: producto.name,
								amounts: Number(producto.amount),
								total: Number(producto.total),
								color: Color()
							})
						}
					}
				}else {
					if(d.store.id == id_store) {
						for(let producto of d.products) {
							if(productos.find(p => p.id == producto.id) != undefined) {
								productos = productos.map(item => {
									if(item.id == producto.id) {
										total_vendidos += Number(producto.amount)
										item.amounts += Number(producto.amount)
										item.total += Number(producto.total)
									}
									return item
								})
							}else {
								total_vendidos += Number(producto.amount)
								productos.push({
									id: producto.id,
									name: producto.name,
									amounts: Number(producto.amount),
									total: Number(producto.total),
									color: Color()
								})
							}
						}
					}
				}
			}
		}
		this.setState({ranking: productos, total_vendidos: total_vendidos})
	}

	renderModalOptions() {
		return (
			<Modal active={this.state.modal}>
        <h2>Opciones</h2>
        <br />
        <div className="btns" style={{display: 'flex', justifyContent: 'center', width: 350, marginBottom: 5}}>
    	    <a href="#" style={{marginRight: 7}} onClick={() => window.ipc.invoke('generate-excel', {cierre: this.cierre}).then(() => alert('¡Excel generado!'))} className="btn-option icon-export">Exportar excel</a>
          <a href="#" onClick={() => this.setState({modal: false})} className="btn-option icon-close">Cerrar</a>
        </div>
      </Modal>
		)
	}

	render() {
		if(this.state.show_venta != null) {
			return (<ShowPago pago={this.state.show_venta} cerrar={() => this.setState({show_venta: null})}/>)
		}else if(this.state.show_gasto != null){
			return (<ShowGasto gasto={this.state.show_gasto} cerrar={() => this.setState({show_gasto: null})}/>)
		}else {
			return (
				<motion.div 
					style={{paddingBottom: 40}}
          initial={{opacity: 0, scale: 0.8, y: 60}}
          animate={{opacity: 1, scale: 1, y: 0}}
          exit={{opacity: 0, scale: 0.8, y: 60}}>

					{this.renderModalOptions()}
		    	<div className={`card ${this.state.loading ? "loading" : ""}`}>
					<div className="title">
						<div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
							<h2>Detalles de informe</h2>
						</div>
					</div>
					<br/>
					<div className="colums">
		    		<div className="colum">
							<div className="ddl">
								<p>Identificador:</p>
								<span></span>
								<p>{this.props.informe.id}</p>
							</div>
							<div className="ddl">
								<p>Fecha:</p>
								<span></span>
								<p>{(this.state.fecha != '') ? this.state.fecha.split(',')[0] : 'N/A'}</p>
							</div>
							<div className="ddl">
								<p>Hora:</p>
								<span></span>
								<p>{(this.state.fecha != '') ? this.state.fecha.split(',')[1].replace('�', '') : 'N/A'}</p>
							</div>
							<div className="divider"></div>
		    		</div>
			    		<div className="colum">
								<a 
									href="#" 
									style={{padding: 14.5}}
									onClick={() => console.log(this.state.user)} className="comercio-setting"
								>
	                <span className="leter" style={{backgroundColor: this.state.user.color, width: 55, height: 55, lineHeight: '53px'}}>{("name" in this.state.user) ? this.state.user.name[0].toUpperCase() : ''}</span>
	                <h2 className="name">{this.state.user.name}</h2>
	              </a>
		            <div className="group-form">
									<select name="store" onChange={(e) => this.setState({loading: true}, () => this.getEstadisticas(e.target.value))}>
										<option value={null}>Todos los comercios</option>
										{this.state.stores.map(s => (
											<option key={s.id} value={s.id}>{s.name}</option>
										))}
									</select>
								</div>
			    		</div>
			    	</div>
					</div>

					<div className="widgets" style={{marginTop: 10}}>
						<div>
							<span className="ventas">Ventas</span>
							<strong>{Price(this.state.ventas)}</strong>
						</div>
						<div>
							<span className="gastos">Gastos</span>
							<strong>{Price(this.state.gastos)}</strong>
						</div>
						<div>
							<span className="efectivo">Efectivo</span>
							<strong>{Price(this.state.efectivo)}</strong>
						</div>
						<div>
							<span className="cuenta">Transferencias</span>
							<strong>{Price(this.state.transferencia)}</strong>
						</div>
						<div>
							<span className="datafono">Datáfono</span>
							<strong>{Price(this.state.datafono)}</strong>
						</div>
						<div>
							<span className="propinas">Propinas</span>
							<strong>{Price(this.state.propinas)}</strong>
						</div>
					</div>

					<div className={`card ${this.state.loading ? "loading" : ""}`}>
						<div className="title" style={{marginBottom: 15}}>
							<h2>Graficos y estadísticas</h2>
						</div>
						<div className="title" style={{marginBottom: 15}}>
							<div style={{width: '100%', display: 'block'}}>
								<a href="#" onClick={() => this.setState({active_chart: 'ventas'})} className={`btn-outline ${this.state.active_chart == 'ventas' ? "active" : ""}`} style={{marginRight: 10}}>Ventas vs Gastos</a>
								<a href="#" onClick={() => this.setState({active_chart: 'entradas'})} className={`btn-outline ${this.state.active_chart == 'entradas' ? "active" : ""}`} style={{marginRight: 10}}>Fuentes de dinero</a>
								<a href="#" onClick={() => this.setState({active_chart: 'ranking'})} className={`btn-outline ${this.state.active_chart == 'ranking' ? "active" : ""}`}>Ranking de productos</a>
							</div>
						</div>
						{this.state.active_chart == 'ventas' && (
							<div className="container-grafica">
								<div className="dona">
									<Doughnut 
									  data={{
										labels: ['Ventas','Gastos'],
										datasets: [{
											label: 'Ventas - VS - Gastos',
											backgroundColor: ['#0080ff', '#ff4e00'] ,
											borderColor: ['#0080ff', '#ff4e00'],
											data: [this.state.ventas, this.state.gastos]
										}]
									  }}
									/> 
								</div>
								<div className="barra">
									<Bar
									  data={{
										labels: ['Ventas - VS - Gastos'],
										datasets: [
										{label: 'Ventas',backgroundColor: '#0080ff',borderColor: '#0080ff',data: [this.state.ventas]},
										{label: 'Gastos',backgroundColor: '#ff4e00',borderColor: '#ff4e00',data: [this.state.gastos]}
										]
									  }}
									/>
								</div>
							</div>
						)}
						{this.state.active_chart == 'entradas' && (
							<div className="container-grafica">
								<div className="dona">
									<Doughnut 
									  data={{
										labels: ['Efectivo', 'Transferencia', 'Datáfono', 'Propinas'],
										datasets: [
										{
											label: 'Fuentes de dinero',
											data: [this.state.efectivo, this.state.transferencia, this.state.datafono, this.state.propinas],
											borderColor: ['#0dff00', '#c000ff', '#3bf6ff','#fff200'],
											backgroundColor: ['#0dff00', '#c000ff', '#3bf6ff','#fff200'],
										}]
									  }}
									/> 
								</div>
								<div className="barra">
									<Bar
									  data={{
										labels: ['Fuentes de dinero'],
										datasets: [
										{label: 'Efectivo',backgroundColor: '#0dff00',borderColor: '#0dff00',data: [this.state.efectivo]},
										{label: 'Transferencia',backgroundColor: '#c000ff',borderColor: '#c000ff',data: [this.state.transferencia]},
										{label: 'Datáfono',backgroundColor: '#3bf6ff',borderColor: '#3bf6ff',data: [this.state.datafono]},
										{label: 'Propinas',backgroundColor: '#fff200',borderColor: '#fff200',data: [this.state.propinas]}
										]
									  }}
									/>
								</div>
							</div>
						)}
						{this.state.active_chart == 'ranking' && (
							<>
								<div className="title" style={{marginBottom: 15}}>
									<span>Ranking de productos vendidos ( Unidades vendidas )</span>
									<span>Total de unidades vendidas: {this.state.total_vendidos}</span>
								</div>
								<div className="container-grafica">
									<div className="dona">
										<Doughnut 
											options={{plugins: {legend: {display: false}}}}
										  data={{
												labels: this.state.ranking.map(p =>  {return p.name}),
												datasets: [{
													label: 'Ranking de productos vendidos ( Unidades vendidas )',
													backgroundColor: this.state.ranking.map(p =>  {return p.color}),
													borderColor: this.state.ranking.map(p =>  {return p.color}),
													data: this.state.ranking.map(p =>  {return p.amounts})
												}]
										  }}
										/> 
									</div>
									<div className="barra">
										<Bar
											options={{plugins: {legend: {display: false}}}}
										  data={{
											labels: ['Ranking de productos vendidos ( Unidades vendidas )'],
											datasets: this.state.ranking.map(p => {
													return {label: p.name, backgroundColor: p.color, borderColor: p.color, data: [p.amounts]}
												})
										  }}
										/>
									</div>
								</div>
								<div className="title" style={{marginBottom: 15}}>
									<span>Ranking de productos vendidos ( Dinero recaudado )</span>
								</div>
								<div className="container-grafica">
									<div className="dona">
										<Doughnut 
											options={{plugins: {legend: {display: false}}}}
										  data={{
												labels: this.state.ranking.map(p =>  {return p.name}),
												datasets: [{
													label: 'Ranking de productos vendidos ( Dinero recaudado )',
													backgroundColor: this.state.ranking.map(p =>  {return p.color}),
													borderColor: this.state.ranking.map(p =>  {return p.color}),
													data: this.state.ranking.map(p =>  {return p.total})
												}]
										  }}
										/> 
									</div>
									<div className="barra">
										<Bar
											options={{plugins: {legend: {display: false}}}}
										  data={{
											labels: ['Ranking de productos vendidos ( Dinero recaudado )'],
											datasets: this.state.ranking.map(p => {
													return {label: p.name, backgroundColor: p.color, borderColor: p.color, data: [p.total]}
												})
										  }}
										/>
									</div>
								</div>
							</>
						)}
					</div>

					<div className={`card ${this.state.loading ? "loading" : ""}`} style={{marginTop: 10}}>
						<div className="title" style={{marginBottom: 15}}>
							<h2>Detalles de movimientos</h2>
						</div>
						<div className="title">
							<div>
								<a href="#" onClick={() => this.setState({active: 'ventas'})} className={`btn-outline ${this.state.active == 'ventas' ? "active" : ""}`} style={{marginRight: 10}}>Ventas</a>
								<a href="#" onClick={() => this.setState({active: 'gastos'})} className={`btn-outline ${this.state.active == 'gastos' ? "active" : ""}`}>Gastos</a>
							</div>
							<div className="access">
								<input type="text" placeholder="Buscar.." onChange={(e) => {
										if(e.target.value.length > 0) {
											if(this.state.active == 'ventas') {
												let coincide = this.state.movimientos.filter(m => m.date.indexOf(e.target.value) > -1 || String(m.total).indexOf(e.target.value) > -1 || m.type.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1);
												this.setState({movimientosShow: coincide});
											}else {
												let coincide = this.state.spents.filter(m => m.name_store.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1 || m.date.indexOf(e.target.value) > -1 || String(m.total).indexOf(e.target.value) > -1 || m.type.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1);
												this.setState({spentsShow: coincide});
											}
										}else {
											if(this.state.active == 'ventas') {
												this.setState({movimientosShow: this.state.movimientos})
											}else {
												this.setState({spentsShow: this.state.spents})
											}
										}
								}}/>
							</div>
						</div>
						<div className="tabla">
					        <table>
				            	{this.state.active == 'ventas' ? (
				            		<>
							            <thead className="fija">
							                <tr>
							                    <th scope="col">Fecha y hora</th>
							                    <th scope="col">Valor</th>
							                    <th scope="col">Tipo</th>
							                    <th scope="col">Descripción</th>
							                    <th scope="col">Acciones</th>
							                </tr>
							            </thead>
							            <tbody>
					            			{this.state.movimientosShow.map((movimiento, i) => (
						                        <tr key={movimiento.id}>
						                            <td>
						                                <span>{movimiento.date.replace('�', '')}</span>
						                            </td>
						                            <td>
						                                <span>{Price(movimiento.total)}</span>
						                            </td>
						                            <td>
						                            	<div className="symbols">
						                            		<span className="icon-ventas"></span>
						                            		{movimiento.type == 'Efectivo' && (<span className="icon-efectivo">{movimiento.type}</span>)}
						                            		{movimiento.type == 'Transferencia' && (<span className="icon-cuenta">{movimiento.type}</span>)}
						                            		{movimiento.type == 'Datafono' && (<span className="icon-datafono">Datáfono</span>)}
						                            	</div>	
						                            </td>
						                            <td>
						                                <span>Pago de cuenta</span>
						                            </td>
						                            <td>
						                            	<div className="btns" style={{justifyContent: 'flex-start'}}>
						                            		<a href="#" style={{marginRight: 10}} onClick={() => this.setState({show_venta: movimiento})} className="icon-eye"></a>
						                            	</div>
						                            </td>
						                        </tr>
							            	))}
							            </tbody>		
				            		</>
				            	) : (
									<>
										<thead>
							                <tr>
							                    <th scope="col">Fecha y hora</th>
							                    <th scope="col">Valor</th>
							                    <th scope="col">Tipo gasto</th>
							                    <th scope="col">Tipo pago</th>
							                    <th scope="col">Comercio</th>
							                    <th scope="col">Acciones</th>
							                </tr>
							            </thead>
							            <tbody>
											{this.state.spentsShow.map((movimiento, i) => (
						                        <tr key={movimiento.id}>
						                            <td>
						                                <span>{movimiento.date}</span>
						                            </td>
						                            <td>
						                                <span>{Price(movimiento.total)}</span>
						                            </td>
						                            <td>
						                                <span>{movimiento.type_spent}</span>
						                            </td>
						                            <td>
						                            	<div className="symbols">
						                            		<span className="icon-gastos"></span>
						                            		{movimiento.type == 'Efectivo' ? (
						                            			<span className="icon-efectivo"></span>
						                            		) : (
						                            			<span className="icon-cuenta"></span>
						                            		)}
						                            		<span>{movimiento.type}</span>
						                            	</div>	
						                            </td>
						                            <td>
						                                <span>{movimiento.name_store}</span>
						                            </td>
						                            <td>
						                            	<div className="btns" style={{justifyContent: 'flex-start'}}>
						                            		<a href="#" style={{marginRight: 10}} onClick={() => this.setState({show_gasto: movimiento})} className="icon-eye"></a>
						                            	</div>
						                            </td>
						                        </tr>
							            	))}
							            </tbody>
									</>
								)}
					        </table>
					    </div>
					</div>
				</motion.div>
			)
		}
	}
}

export default ReportDetails
