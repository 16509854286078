const Price = (value) => {
	let price = Number(value);
	return new Intl.NumberFormat('es-ES',{style: 'currency', currency: 'COP', maximumFractionDigits: 0, maximumSignificantDigits: 10}).format(price)
}

const Color = () => {
  let letras = ["a","b","c","d","e","f","0","1","2","3","4","5","6","7","8","9"];
  let c = "#"+letras[(Math.random()*15).toFixed(0)]+letras[(Math.random()*15).toFixed(0)]+letras[(Math.random()*15).toFixed(0)]+letras[(Math.random()*15).toFixed(0)]+letras[(Math.random()*15).toFixed(0)]+letras[(Math.random()*15).toFixed(0)];
  return c;
}


exports.Price = Price;
exports.Color = Color;
