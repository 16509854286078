import React, {Component} from 'react';
import {Price} from '../utiles/utilidades'
import {motion} from 'framer-motion';

class ShowPago extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pago: this.props.pago,
			modal_pago: false,
			type_pay: 'Efectivo',
			received: 0,
			print_invoice: false,
			modal_change_pay: false,
			modal_opciones: false,
		}
	}
	
	getDescuento(off, off_type) {
		if(off != 0 && off != '' && off != null) {
			if(off_type == 'porcentaje') {
				return off+'%'
			}else {
				return Price(off);
			}
		}else {
			return '0'
		}
	}

	render() {
		return (
			<motion.div 
	          initial={{opacity: 0, scale: 0.8, y: 60}}
	          animate={{opacity: 1, scale: 1, y: 0}}
	          exit={{opacity: 0, scale: 0.8, y: 60}}>

				<div className="card">

					<div className="title">
			    		<div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
			    			<a href="#" onClick={() => this.props.cerrar()} className="ir-atras icon-arrow-left"></a>
							<h2>Detalles de movimiento</h2>
						</div>
					</div>
					<div className="colums">
			    		<div className="colum">
							<div className="ddl">
								<p>Identificador:</p>
								<span></span>
								<p>{this.state.pago.id}</p>
							</div>
							<div className="ddl">
								<p>Fecha:</p>
								<span></span>
								<p>{("date" in this.state.pago) ? this.state.pago.date.split(',')[0] : 'N/A'}</p>
							</div>
							<div className="ddl">
								<p>Hora:</p>
								<span></span>
								<p>{("date" in this.state.pago) ? this.state.pago.date.split(',')[1].replace('�', '') : 'N/A'}</p>
							</div>
							<div className="ddl">
								<p>Usuario:</p>
								<span></span>
								<p>{this.state.pago.name_invoice ? this.state.pago.name_invoice : 'N/A'}</p>
							</div>
							<div className="ddl">
								<p>Método de pago:</p>
								<span></span>
								<p>{(this.state.pago.type == 'Datafono') ? 'Datáfono' : this.state.pago.type}</p>
							</div>
			    		</div>
			    		<div className="colum">
							<div className="ddl">
								<p>Total cuenta</p>
								<span></span>
								<p>{Price(this.state.pago.total)}</p>
							</div>
							<div className="ddl">
								<p>Descuento cuenta</p>
								<span></span>
								<p>{this.state.pago.off}</p>
							</div>
							<div className="ddl">
								<p>Tipo de descuento</p>
								<span></span>
								<p>{(this.state.pago.off != 0) ? this.state.pago.off_type : 'N/A'}</p>
							</div>
							<div className="ddl">
								<p>Propina</p>
								<span></span>
								<p>{Price(this.state.pago.propina)}</p>
							</div>
							<div className="ddl">
								<p>Valor recibido</p>
								<span></span>
								<p>{("received" in this.state.pago) ? Price(this.state.pago.received) : 0}</p>
							</div>
							<div className="ddl">
								<p>Cambio</p>
								<span></span>
								<p>{("received" in this.state.pago) ? Price(Number(this.state.pago.received) - Number(this.state.pago.total)) : 0}</p>
							</div>
			    		</div>
			    	</div>
			    	<div className="divider"></div>
					
					{("data" in this.state.pago) && (
						<>
							{this.state.pago.data.map((pedido, i) => (
								<div key={String(pedido.store.id)}>
									<span style={{fontSize: 20}}>{pedido.store.name}</span>
									<div className="tabla">
								        <table>
								            <thead>
								                <tr>
								                    <th scope="col">Producto</th>
								                    <th scope="col">Valor</th>
								                    <th scope="col">Descuento</th>
								                    <th scope="col">De cortesía</th>
								                    <th scope="col">Para llevar</th>
								                    <th scope="col">Tamaño</th>
								                    <th scope="col">N° Adiciones</th>
								                </tr>
								            </thead>
								            <tbody>
								            	{pedido.products.map((producto) => (
							                        <tr key={producto.id_in_order}>
							                            <td>
							                                <span>{producto.name} x{producto.amount}</span>
							                            </td>
							                            <td>
							                                <span>{Price(producto.total)}</span>
							                            </td>
							                            <td>
							                            	<span>{this.getDescuento(producto.off, producto.off_type)}</span>
							                            </td>
							                            <td>
							                                <span>{producto.courtesy ? 'Si' : 'No'}</span>
							                            </td>
							                            <td>
							                                <span>{producto.to_go ? 'Si' : 'No'}</span>
							                            </td>
							                            <td>
							                                <span>{producto.size != null ? producto.size.name : 'N/A'}</span>
							                            </td>
							                            <td>
							                                <span>{producto.adds.length}</span>
							                            </td>
							                        </tr>
								            	))}
								            </tbody>
								        </table>
								    </div>
								</div>
							))}
						</>
					)}
				</div>
			</motion.div>
		)
	}
}
export default ShowPago;