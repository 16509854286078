import React from 'react'
import Report from './screens/ReportDetails'

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      fails: false,
    }
  }

  componentDidMount() {
    var host = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost/insitu-informe/public/back/index.php' : (window.location.protocol+'//'+window.location.hostname+'/back/index.php');
    let id = window.location.pathname.slice(1, window.location.pathname.length)
    if(id != '') {
      fetch(host, {
        method: 'POST',
        headers: {'Token': 'ZXN0ZSBlcyBlbCB0b2tlbiBkZSBhY2Nlc28uLi4='},
        body: JSON.stringify({id: id})
      }).then(d => d.text()).then(res => {
        if(res.indexOf('{') > -1) {
          res = JSON.parse(res);
          this.setState({informe: res, loading: false})
        }
      }).catch(err => {
        this.setState({fails: true, loading: false})
      })
    }else {
      this.setState({fails: true, loading: false})
    }
  }

  render() {
    return (
      <div className="App">
          {this.state.loading ? (
            <div className="cargador">
              <span>Cargando informe...</span>
              <div className="loader"></div>
            </div>
          ) : (
            <>
              {this.state.fails ? (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
                  <span style={{fontSize: '3.5rem', display: 'block', marginBottom:30, width: 100, height: 100, borderRadius: 50, border: '3px solid #fff', textAlign: 'center', lineHeight: '80px'}}>:(</span>
                  <span style={{fontSize: '3.5rem'}}>¡Ooops!</span>
                  <span style={{fontSize: '2.5rem', marginBottom:30}}>No encotramos nada por aquí</span>
                </div>
              ) : (
                <Report informe={this.state.informe}/>
              )}
            </>
          )}
      </div>
    )
  }
}
